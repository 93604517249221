import $ from 'n-zepto'
import useSDK from '../data/useSDK'





const useAPI = () => {


    const { getToken, getVer, getPF, getApiDomain, getFP, getClickId } = useSDK()


    // const setToken = (token) => {
    //     window.localStorage.setItem("_token", token)
    // }
    // const getToken = () => {
    //     return window.localStorage.getItem("_token")

    // }


    const request = async (url, params, { } = {}) => {
        //先从SDK获取请求api的域名，如果获取不到就从打包定义的获取;从sdk获取的目的是，不用每次掉备都要从新打包wap
        //但是有些旧的sdk没有提供getApiDomain方法，只能打包时提供
        let api_domain = getApiDomain();
        if (!api_domain) {
            api_domain = process.env?.REACT_APP_DOMAIN;
        }
        let fp = getFP();
        let ckid = getClickId();
        let pf = getPF();
        // if (url?.startsWith("http") === false) url = process.env?.REACT_APP_DOMAIN + url;
        if (url?.startsWith("http") === false) url = api_domain + url;
        // console.log('getToken()',getToken())
        return await new Promise((resolve, reject) => {
            $.ajax(
                {
                    url, data: { token: getToken(), app_ver: getVer(), pf, ...params },
                    type: "get",
                    dataType: 'json',
                    // headers: { fp, ckid, pf },//国内的不需要传
                    success: (rs) => {
                        resolve(rs)
                    }, error: rs => {
                        reject(rs)
                    }
                }
            )
        });




    }

    return { request }

}

export default useAPI;